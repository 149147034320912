import * as React from "react";
import { RandomAccessOne, IdentifierType } from "@shared-ui/retail-product-random-access-one";
import { RandomAccessOnesProps } from "./typings";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { useDialog } from "@shared-ui/dialog-context";
import { PropertyFilteredReviewsDialog } from "components/shared/PropertyFilteredReviewsDialog/PropertyFilteredReviewsDialog";

export const RandomAccessOneWrapper: React.FC<RandomAccessOnesProps> = ({ templateComponent, context }) => {
  const {
    metadata: { id },
  } = templateComponent;

  const [isReviewsDialogOpen, reviewsDialogActions, ReviewsDialogComponent] = useDialog("sui-see-all-reviews-dialog");

  const { featureId } = templateComponent.config;
  return (
    <div id={id}>
      <RandomAccessOne
        inputs={{
          featureId,
          productIdentifier: {
            id: context.searchContext.location.id ? String(context.searchContext.location.id) : "",
            type: IdentifierType.PROPERTY_ID,
            travelSearchCriteria: {
              property: {
                primary: {
                  dateRange: null,
                  rooms: [{ adults: 2 }],
                  destination: {
                    regionId: String(context?.searchContext?.location?.parent?.id),
                  },
                },
                secondary: {},
              },
            },
          },
        }}
      />
      <UitkSheetTransition isVisible={isReviewsDialogOpen}>
        <ReviewsDialogComponent>
          <PropertyFilteredReviewsDialog context={context} onClose={reviewsDialogActions.closeDialog} />
        </ReviewsDialogComponent>
      </UitkSheetTransition>
    </div>
  );
};

export default RandomAccessOneWrapper;
